.srfs__table {
  width: 100%;
  max-width: calc(100vw - 120px);
  padding: 0.5rem 0 1rem 0;
}

.searchers__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.certificate__modal {
  width: 95vw !important;
  height: 100vh !important;
}

.menu_item:hover {
  color: rgba(1, 182, 245, 1);
}

.download_icon svg {
  transition: transform 1s ease-in-out;
}

.download_icon:hover svg {
  transform: rotate(360deg) scale(1.2);
}


table tbody tr td {
  vertical-align: middle;
}