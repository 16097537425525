.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
