@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Oswald&family=STIX+Two+Text:ital@1&display=swap);
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.inline-flex {
  display: inline-flex;
  background-size: cover;
}

.user-info {
  margin-top: 0.3rem;
  width: 90%;
  padding: 0.5rem;
  display: flex;
}

.makeStyles-toolbar-9 {
  width: 10%;
}

.wrapped {
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
}
.wrapped h1 {
  font-family: "Oswald", sans-serif;
  margin-top: 0.8rem;
  font-size: 0.9rem;
  line-height: 1rem;
  color: rgb(158, 44, 224);
}

.wrapped h2 {
  font-family: "STIX Two Text", serif;
}

.MuiTabs-flexContainer {
  justify-content: space-around;
}

.new_file_modal, .new_user_modal{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem;
}

.new_file_modal h2,.new_user_modal h2{
  font-size: 1.5rem;
  width: 100%;
  padding:1rem;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.button_container{
  width: 100%;
  text-align: center;
  padding: 1rem;
}

.new_file_error, .new_user_error{
  color:red;
}

.new_file_success{
  color:blue;
}

.new_user_success{
  color:green;
}
.new_user_modal label{
  font-size: 1.2rem;
  padding-top: 1rem;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
@media only screen and (min-width: 1440px) {
  .MuiBreadcrumbs-root {
    width: 20%;
  }
  .tablist {
    width: 65%;
  }
}

@media only screen and (min-width: 1920px) {
  .MuiBreadcrumbs-root {
    width: 18%;
  }
  .tablist {
    width: 60%;
  }
}

.enabled {
  color: black;
}

.disabled {
  color: whitesmoke;
}


.MuiBreadcrumbs-li,
.MuiBreadcrumbs-separator {
  color: aliceblue;
}

.MuiBreadcrumbs-li p {
  color: white;
}

.body-img img {
  background-size: cover;
  width: 90vw;
  height: 90vh;
}

.body-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 90vh;
  width: 90vw;
}

iframe{
  width: 100%;
  height: 100%;
}

.react-pdf__Document{
  width: 90vw;
  height: 90vh;
  margin: auto;
}

.react-pdf__Page__canvas{
width: 90vw !important;
height: auto !important;
margin:auto !important;
max-width: 900px;
}

.pagenumber{
  width: 100%;
  text-align: center;
  font-size: large;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .react-pdf__Page__canvas{
    width: 90vw !important;
    height: auto !important;
    margin:auto;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .react-pdf__Page__canvas{
    width: 90vw !important;
    height: auto !important;
    margin:auto;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .react-pdf__Document{
    width: 70vw;
    height: auto;
  }
  .react-pdf__Page__canvas{
  width: 70vw !important;
  height: auto !important;
  margin:auto;
  }}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .react-pdf__Document{
    width: 70vw;
    height: auto;
  }
  .react-pdf__Page__canvas{
  width: 70vw !important;
  height: auto !important;
  margin:auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
.srfs__table {
  width: 100%;
  max-width: calc(100vw - 120px);
  padding: 0.5rem 0 1rem 0;
}

.searchers__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.certificate__modal {
  width: 95vw !important;
  height: 100vh !important;
}

.menu_item:hover {
  color: rgba(1, 182, 245, 1);
}

.download_icon svg {
  transition: transform 1s ease-in-out;
}

.download_icon:hover svg {
  transform: rotate(360deg) scale(1.2);
}


table tbody tr td {
  vertical-align: middle;
}
.custom__search__container {
  padding: 1rem;
  width: 250px;
}


.background {
  width: 100vw;
  height: 100vh;
  background-size: cover;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  flex-wrap: wrap;
}

.topgap {
  margin-top: 10px;
}

.redcolor {
  color: red;
  text-align: center;
}

.login-page-header{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
}

.login-page-header h2{
  font-size: 4rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.login-page-footer{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: rgb(20, 23, 26);
  margin-top: -75px;
}

.w-100{
  width: 100%;
  text-align: center;
  color: aliceblue;
}

.login-page-footer h2{
  font-size: 2.5rem;
  padding:0.5rem;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.login-page-footer h1{
  font-size: 2.5rem;
  padding: 1rem;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.login-page-footer a{
  font-size: 1.5rem;
  padding: 0.75rem;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.login-page-footer h3{
  font-size: 2rem;
  padding: 1rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

