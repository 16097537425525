.background {
  width: 100vw;
  height: 100vh;
  background-size: cover;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  flex-wrap: wrap;
}

.topgap {
  margin-top: 10px;
}

.redcolor {
  color: red;
  text-align: center;
}

.login-page-header{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
}

.login-page-header h2{
  font-size: 4rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.login-page-footer{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: rgb(20, 23, 26);
  margin-top: -75px;
}

.w-100{
  width: 100%;
  text-align: center;
  color: aliceblue;
}

.login-page-footer h2{
  font-size: 2.5rem;
  padding:0.5rem;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.login-page-footer h1{
  font-size: 2.5rem;
  padding: 1rem;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.login-page-footer a{
  font-size: 1.5rem;
  padding: 0.75rem;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.login-page-footer h3{
  font-size: 2rem;
  padding: 1rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}