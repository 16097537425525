.body-img img {
  background-size: cover;
  width: 90vw;
  height: 90vh;
}

.body-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 90vh;
  width: 90vw;
}

iframe{
  width: 100%;
  height: 100%;
}

.react-pdf__Document{
  width: 90vw;
  height: 90vh;
  margin: auto;
}

.react-pdf__Page__canvas{
width: 90vw !important;
height: auto !important;
margin:auto !important;
max-width: 900px;
}

.pagenumber{
  width: 100%;
  text-align: center;
  font-size: large;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .react-pdf__Page__canvas{
    width: 90vw !important;
    height: auto !important;
    margin:auto;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .react-pdf__Page__canvas{
    width: 90vw !important;
    height: auto !important;
    margin:auto;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .react-pdf__Document{
    width: 70vw;
    height: auto;
  }
  .react-pdf__Page__canvas{
  width: 70vw !important;
  height: auto !important;
  margin:auto;
  }}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .react-pdf__Document{
    width: 70vw;
    height: auto;
  }
  .react-pdf__Page__canvas{
  width: 70vw !important;
  height: auto !important;
  margin:auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}